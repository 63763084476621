import './Demo.css';
import Navbar from '../../components/navbar/Navbar'
import DemoOptionCards from '../../components/demoOptionCards/DemoOptionCards';
import PhoneDemoFlow from '../../components/phoneDemoFlow/PhoneDemoFlow';
import {Helmet} from "react-helmet";
import { useState, useEffect, useRef } from 'react';

import locationStockholm from '../../assets/locationStockholm.jpg'
import locationSanFrancisco from '../../assets/locationSanFrancisco.png'
import locationRome from '../../assets/locationRome.png'

import parkingSignStrandvägen from '../../assets/parkingSignStrandvägen.jpeg'
import parkingSignValhallavägen from '../../assets/parkingSignValhallavägen.jpeg'
import parkingSignOdengatan from '../../assets/parkingSignOdengatan.jpeg'

import swedenParkingSign1 from '../../assets/swedenParkingSign1.png'
import swedenParkingSign2 from '../../assets/swedenParkingSign2.png'
import swedenParkingSign3 from '../../assets/swedenParkingSign3.png'

import usaParkingSign1 from '../../assets/usaParkingSign1.png'
import usaParkingSign2 from '../../assets/usaParkingSign2.png'
import usaParkingSign3 from '../../assets/usaParkingSign3.png'

import italyParkingSign1 from '../../assets/italyParkingSign1.png'
import italyParkingSign2 from '../../assets/italyParkingSign2.png'
import italyParkingSign3 from '../../assets/italyParkingSign3.png'


function Demo() {
  var root = document.getElementById("root")
  root.scrollTo(0, 0);

  const [selectedCity, setSelectedCity] = useState(false);
  const [selectedParkingSign, setSelectedParkingSign] = useState(false);
  const [resetDemo, setResetDemo] = useState(false);

  const countryOptionsRef = useRef(null);

  const parkingSignsCity1Ref = useRef(null);
  const parkingSignsCity2Ref = useRef(null);
  const parkingSignsCity3Ref = useRef(null);

  const phoneDemoContainerRef = useRef(null);




  useEffect(() => {
    setTimeout(() => {
      countryOptionsRef.current.classList.add('demoContainerAnimation');
    }, 200);
  }, []);

  useEffect(() => {
    if (selectedCity != false) {
      setTimeout(() => {
        if (selectedCity == 1) {
          parkingSignsCity1Ref.current.classList.add('demoContainerAnimation');
        } else if (selectedCity == 2) {
          parkingSignsCity2Ref.current.classList.add('demoContainerAnimation');
        }
        else if (selectedCity == 3) {
          parkingSignsCity3Ref.current.classList.add('demoContainerAnimation');
        }
      }, 200);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedParkingSign != false) {
      setTimeout(() => {
        phoneDemoContainerRef.current.classList.add('demoContainerAnimation');
      }, 200);
    }
  }, [selectedParkingSign]);

  useEffect(() => {
    
    if (resetDemo == true) {
      
      setSelectedCity(false);
      setSelectedParkingSign(false);
      setResetDemo(false);
    
      countryOptionsRef.current.classList.remove('demoContainerAnimation');
      parkingSignsCity1Ref.current.classList.remove('demoContainerAnimation');
      parkingSignsCity2Ref.current.classList.remove('demoContainerAnimation');
      parkingSignsCity3Ref.current.classList.remove('demoContainerAnimation');
      phoneDemoContainerRef.current.classList.remove('demoContainerAnimation');

      setTimeout(() => {
        countryOptionsRef.current.classList.add('demoContainerAnimation');
      }, 500);
    }
  }
  , [resetDemo]);

  return (
    <div className='demoBody'>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Demo</title>
            <link rel="canonical" href="http://nofine.se/demo" />
        </Helmet>
        <Navbar textColor={"black"} backgroundBlur={true} backgroundColor={"dark"}/>
        <div className='demoNavbarSpacer'>

        </div>
        <div className='demoContainer'>
          <div className='demoCountryOptions' ref={countryOptionsRef}>
            <DemoOptionCards demoOptionText1={"Stockholm"} demoOptionText2={"San Francisco"} demoOptionText3={"Rome"} setClickState={setSelectedCity} resetDemo={resetDemo} image1={locationStockholm} image2={locationSanFrancisco} image3={locationRome}/>
          </div>
          <div className='demoParkingSignOptions' ref={parkingSignsCity1Ref}>
            <DemoOptionCards demoOptionText1={"Strandvägen"} demoOptionText2={"Valhallavägen"} demoOptionText3={"Odengatan"} setClickState={setSelectedParkingSign} resetDemo={resetDemo} image1={swedenParkingSign1} image2={swedenParkingSign2} image3={swedenParkingSign3}/>
          </div>
          <div className='demoParkingSignOptions' ref={parkingSignsCity2Ref}>
            <DemoOptionCards demoOptionText1={"Palou Ave"} demoOptionText2={"Lisbon st"} demoOptionText3={"Staples Ave"} setClickState={setSelectedParkingSign} resetDemo={resetDemo} image1={usaParkingSign1} image2={usaParkingSign2} image3={usaParkingSign3}/>
          </div>
          <div className='demoParkingSignOptions' ref={parkingSignsCity3Ref}>
            <DemoOptionCards demoOptionText1={"Via del Corso"} demoOptionText2={"Via Nazionale"} demoOptionText3={"Via dei Fori Imperiali"} setClickState={setSelectedParkingSign} resetDemo={resetDemo} image1={italyParkingSign1} image2={italyParkingSign2} image3={italyParkingSign3}/>
          </div>

          <div className='demoPhoneContainer' ref={phoneDemoContainerRef}>
            <PhoneDemoFlow cityNumber={selectedCity} parkingSignNumber={selectedParkingSign} resetDemo={resetDemo} setResetDemo={setResetDemo}/>
          </div>
        </div>
    </div>
  );
}

export default Demo;
