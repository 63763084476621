import React, { useEffect, useRef } from 'react';
import './ProgressBar.css';

function ProgressBar({ width, progress }) {
    const progressBarRef = useRef(null);

    useEffect(() => {
        const handleIntersection = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.querySelector('.ProgressBarFiller').style.width = progress;
                    observer.unobserve(entry.target);
                }
            });
        };

        const options = {
            threshold: 0.1
        };

        const observer = new IntersectionObserver(handleIntersection, options);
        if (progressBarRef.current) {
            observer.observe(progressBarRef.current);
        }

        return () => {
            if (progressBarRef.current) {
                observer.unobserve(progressBarRef.current);
            }
        };
    }, [progress]);

    return (
        <div className="ProgressBarBody" style={{ width: width }} ref={progressBarRef}>
            <div className="ProgressBarFiller"></div>
        </div>
    )
}

export default ProgressBar;
