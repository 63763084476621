import './DemoPage.css';
import DemoAppInformation from '../demoappinformation/DemoAppInformation';
import DemoIntroPage from '../demointropage/DemoIntroPage';
import Background from '../background/Background';
import Phone from '../phone/Phone'
import React, { useState, useEffect } from 'react'
import Text1left from '../demoAppInformationText/Text1left'
import Text2left from '../demoAppInformationText/Text2left';



function DemoPage() {
const [height, setHeight] = useState("40vh")
const [display1, setDisplay1] = useState(1)
const [display2, setDisplay2] = useState(0)
const [display3, setDisplay3] = useState(0)
const [textOpacity1, setTextOpacity1] = useState(0)
const [textOpacity2, setTextOpacity2] = useState(0)


const listenScrollEvent2 = (event) => {
  var root = document.getElementById("root")
  var yPosition = root.scrollTop
  let height = window.innerHeight;
  if (yPosition <1.4*height) {
    return setHeight("40vh"), setDisplay1(1), setDisplay2(0), setDisplay3(0), setTextOpacity1(0), setTextOpacity2(0)

  } else if (yPosition >= 1.3*height && yPosition <= 2*height) {
    return setHeight("80vh"), setDisplay1(0), setDisplay2(1), setDisplay3(0), setTextOpacity1(1), setTextOpacity2(0)

  } else if (yPosition > 2*height && yPosition <= 3.4*height) {
    return setHeight("80vh"), setDisplay1(0), setDisplay2(0), setDisplay3(1), setTextOpacity1(0), setTextOpacity2(1)
 
  } else if (yPosition > 3.4*height) {
    return setHeight("80vh"), setDisplay1(0), setDisplay2(0), setDisplay3(0), setTextOpacity1(0), setTextOpacity2(0)
  }
}

useEffect(() => {
  document.getElementById("root").addEventListener('scroll', listenScrollEvent2);

  return () =>
  document.getElementById("root").removeEventListener('scroll', listenScrollEvent2);
}, []);  


window.onbeforeunload = function () {
  var root = document.getElementById("root")
  root.scrollTo(0, 0);
}

/* 
var finished = true

const listenScrollEvent = async (event) => {
  let height = window.innerHeight;
  if (event.deltaY > 0 && finished) {
    disable()
    console.log("TJA")
    finished = false
    scrolldown(height)
    finished = await timer(height)
    finished = true
    enable()

  } else if (event.deltaY < 0 && finished) {
    disable()
    console.log("då")
    finished = false
    scrollUp(height)
    finished = await timer(height)
    finished = true
    enable()
  }
} */







/* useEffect(() => {
  window.addEventListener('scroll', console.log("hej"));

  return () =>
    window.removeEventListener('scroll', console.log("hej"));
}, []);  
 */
//document.getElementById("body").addEventListener("touchstart", console.log("hej"));



//window.addEventListener('Touch', console.log("hej"));


/* $('body').bind("mousewheel", function() {
  return false;
}); 
*/

/* window.addEventListener('mousedown', (event) => {console.log("sfsdf")});

document.addEventListener('mousewheel', function(e) {
  //console.log(e.wheelDelta, "sdfsd");
});

document.addEventListener('touchstart', function(e) {
  //console.log(e, "sqwwqdfsdf");
});




function disable(){
  document.querySelector('body').addEventListener('wheel', preventScroll, {passive: false});
}

function enable(){
    document.querySelector('body').removeEventListener('wheel', preventScroll, {passive: false});
}

function preventScroll(e){
    e.preventDefault();
    e.stopPropagation();

    return false;
}













function scrolldown(height) {
  var n = window.scrollY/height
  n = n+1
  n = Math.round(n)
  console.log("n____", n)
  setPosition(n)
  window.scroll({
    top: n*height,
    behavior:'smooth'
  })
}

function scrollUp(height) {
  var n = window.scrollY/height
  n = n-1
  n = Math.round(n)
  console.log("n____", n)
  setPosition(n)
  window.scroll({
    top: n*height,
    behavior:'smooth'
  })
}

useEffect(() => {
  window.addEventListener('wheel', listenScrollEvent);

  return () =>
    window.removeEventListener('wheel', listenScrollEvent);
}, []); 

const timer = async() => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(
      );
    }, 1200);
  });
}  */

/* const timerdown = async(height) => {
  return new Promise(resolve => {
      var n = window.scrollY/height
      n = n+1
      n = Math.round(n)
      let desiredHeight = n*height;
      while (window.scrollY != desiredHeight) {

      }
      resolve();
  });
} 

const timerup = async(height) => {
  return new Promise(resolve => {
      var n = window.scrollY/height
      n = n-1
      n = Math.round(n)
      let desiredHeight = n*height;
      while (window.scrollY != desiredHeight) {

      }
      resolve();
  });
}  */

  return (
    <div className='teste'>
    <Background />
    <DemoIntroPage />
    <Phone inputheight={height} display1={display1} display2={display2} display3={display3}/>
    <DemoAppInformation left_side={<Text1left textOpacity={textOpacity1}/>}/>
    <DemoAppInformation left_side={<Text2left textOpacity={textOpacity2}/>}/>
    </div>
  );
}

export default DemoPage;
