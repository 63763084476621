import React from 'react'
import './Backdrop.css'
import {
    Link,
  } from "react-router-dom";

function Backdrop() {
  return (
        <div className='backdropBlur' id="backdropBlur">
            <Link to='/'className='backdropLink'>Home</Link>
            <Link to='/demo'className='backdropLink'>Demo</Link>
            <Link to='/about'className='backdropLink'>About</Link>
            <Link to='/contact'className='backdropLink'>Contact</Link>
        </div>
  )
}

export default Backdrop;
