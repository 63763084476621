import './MentionedInSection.css'
import parkingImage from '../../assets/Parking-pana.png'
import svtLogo from '../../assets/svtLogo.png'
import mittI from '../../assets/mittI.png'
import cafe from '../../assets/cafe.png'
import p4Stockholm from '../../assets/p4Stockholm.png'
import feber from '../../assets/feber.png'
import React, { useEffect, useState, useRef } from 'react';

function MentionedInSection() {
    const [visibleLogos, setVisibleLogos] = useState([]);
    const logosRef = useRef(null);
    const delayBetweenPopups = 300; // you can adjust this delay as needed

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Define the order and groups of popping up
                    const popupGroups = [[2], [1, 3], [0, 4]];
                    const initialDelay = 500; // You can adjust this initial delay as needed
    
                    popupGroups.forEach((group, idx) => {
                        setTimeout(() => {
                            setVisibleLogos(prevVisibleLogos => [...prevVisibleLogos, ...group]);
                        }, initialDelay + (delayBetweenPopups * idx));
                    });
    
                    // Disconnect observer after triggering
                    observer.disconnect();
                }
            });
        });
    
        observer.observe(logosRef.current);
    
        return () => observer.disconnect();
    
    }, [delayBetweenPopups]);
    

    return (
        <div className="MentionedInSectionBody">
            <div className="MentionedInSectionContainer">
                <div className="MentionedInSectionHeader">
                    <h1>Mentioned in</h1>
                </div>
                <div className="MentionedInSectionLogosBody" ref={logosRef}>
                    <div className="MentionedInSectionLogosContainer">
                        {[feber, mittI, cafe, svtLogo, p4Stockholm].map((logoSrc, idx) => (
                            <div key={idx} className="MentionedInSectionLogo">
                                <img 
                                    src={logoSrc} 
                                    alt="Logo" 
                                    className={`MentionedInSectionLogoImage ${visibleLogos.includes(idx) ? 'isVisible' : ''}`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MentionedInSection;