import './AboutUsSection.css'
import aboutUsImage from '../../assets/aboutUsImage.png'
import kthLogo from '../../assets/kthLogo.png'

function AboutUsSection() {

    var demoHeader = "Making parking signs easier to figure out"
    var demoText = "We are here to make the parking experience more enjoyable by providing a state of the art solution to ease parking signs"
    var demoButtonText = "Try it out"

    return (
        <div className="AboutUsSectionBody">
            <div className="AboutUsSectionContainer">
                <div className="AboutUsSectionTextContainer">
                    <h1 className="AboutUsSectionHeader">Our vision</h1>
                    <p className="AboutUsSectionText">Nofine was founded with the intent of making the parking experience easier. We believe that reading and understanding parking signs should be swift and clear, however, that is far from the reality for many.</p> 
                    <p className="AboutUsSectionText">We look to provide a solution that removes the element of uncertainty when parking by developing a mobile app that allows our users to simply scan parking signs they find difficult.</p>
                   <h1 className="AboutUsSectionHeader">Backed by</h1>
                   <img src={kthLogo} alt="Cars parked" className='AboutUsSectionLogo'/> 
                </div>
                <div className="AboutUsSectionImageContainer">
                    <img src={aboutUsImage} alt="Cars parked" className='AboutUsSectionImage'/>
                </div>
            </div>
        </div>
    )
}

export default AboutUsSection