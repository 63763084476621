import './ScrollPositionsDot.css'

function goTo(position){
    var root = document.getElementById("root")
    let height = window.innerHeight;
    //setPosition(position)
    console.log("kuken")
    root.scroll({
        top: position*height,
        behavior:'smooth'
      })

}

const ScrollPositionsDot = ({currentPosition, position}) => {
    console.log(position,currentPosition,"current")
    if (currentPosition == position){
        if (position == 1){
            var backgroundColor = "black"
        } else {
            var backgroundColor = "white"
        }
    
        var size = "12px"
    } else {
        var backgroundColor = "#bababa"
    }



    return (
        <div className='containerScrollPositionsDot' onClick={event => goTo(position)}>
            <div className='scrollPositionsDot' style={{background: backgroundColor, height: size, width:size}}>
            
            </div> 
        </div>
    );
  }
    
    export default ScrollPositionsDot;