import './PhoneDemoFlow.css';
import Navbar from '../navbar/Navbar'
import {Helmet} from "react-helmet";
import strandvägenPhone1 from '../../assets/iphone14pro1.png'
import strandvägenPhone2 from '../../assets/iphone14pro2.png'
import strandvägenPhone3 from '../../assets/iphone14pro3.png'
import { useState, useEffect, useRef } from 'react';

import img1Italy1 from '../../assets/appFlows/appFlowItaly1_image1.png'
import img1Italy2 from '../../assets/appFlows/appFlowItaly1_image2.png'
import img1Italy3 from '../../assets/appFlows/appFlowItaly1_image3.png'
import img2Italy1 from '../../assets/appFlows/appFlowItaly2_image1.png'
import img2Italy2 from '../../assets/appFlows/appFlowItaly2_image2.png'
import img2Italy3 from '../../assets/appFlows/appFlowItaly2_image3.png'
import img3Italy1 from '../../assets/appFlows/appFlowItaly3_image1.png'
import img3Italy2 from '../../assets/appFlows/appFlowItaly3_image2.png'
import img3Italy3 from '../../assets/appFlows/appFlowItaly3_image3.png'

import img1Sweden1 from '../../assets/appFlows/appFlowSweden1_image1.png'
import img1Sweden2 from '../../assets/appFlows/appFlowSweden1_image2.png'
import img1Sweden3 from '../../assets/appFlows/appFlowSweden1_image3.png'
import img2Sweden1 from '../../assets/appFlows/appFlowSweden2_image1.png'
import img2Sweden2 from '../../assets/appFlows/appFlowSweden2_image2.png'
import img2Sweden3 from '../../assets/appFlows/appFlowSweden2_image3.png'
import img3Sweden1 from '../../assets/appFlows/appFlowSweden3_image1.png'
import img3Sweden2 from '../../assets/appFlows/appFlowSweden3_image2.png'
import img3Sweden3 from '../../assets/appFlows/appFlowSweden3_image3.png'

import img1Usa1 from '../../assets/appFlows/appFlowUsa1_image1.png'
import img1Usa2 from '../../assets/appFlows/appFlowUsa1_image2.png'
import img1Usa3 from '../../assets/appFlows/appFlowUsa1_image3.png'
import img2Usa1 from '../../assets/appFlows/appFlowUsa2_image1.png'
import img2Usa2 from '../../assets/appFlows/appFlowUsa2_image2.png'
import img2Usa3 from '../../assets/appFlows/appFlowUsa2_image3.png'
import img3Usa1 from '../../assets/appFlows/appFlowUsa3_image1.png'
import img3Usa2 from '../../assets/appFlows/appFlowUsa3_image2.png'
import img3Usa3 from '../../assets/appFlows/appFlowUsa3_image3.png'



function PhoneDemoFlow({cityNumber, parkingSignNumber, resetDemo, setResetDemo}) {
  var root = document.getElementById("root")
  root.scrollTo(0, 0);

  const [flowState, setFlowState] = useState(0);


  const images = {
    1: {
      1: [img1Sweden1, img1Sweden2, img1Sweden3],
      2: [img2Sweden1, img2Sweden2, img2Sweden3],
      3: [img3Sweden1, img3Sweden2, img3Sweden3],
    }
    ,
    2: {
      1: [img1Usa1, img1Usa2, img1Usa3],
      2: [img2Usa1, img2Usa2, img2Usa3],
      3: [img3Usa1, img3Usa2, img3Usa3],
    },
    3: {
      1: [img1Italy1, img1Italy2, img1Italy3],
      2: [img2Italy1, img2Italy2, img2Italy3],
      3: [img3Italy1, img3Italy2, img3Italy3],
    }
  };
  
  if (images[cityNumber] && images[cityNumber][parkingSignNumber]) {
    var [image1, image2, image3] = images[cityNumber][parkingSignNumber];
  }

  const phoneDemoFlow1Ref = useRef(null);
  const phoneDemoFlow2Ref = useRef(null);
  const phoneDemoFlow3Ref = useRef(null);

  useEffect(() => {
    if (flowState == 1) {
      phoneDemoFlow1Ref.current.classList.add('phoneDemoFlowPhoneAnimation2');
      setTimeout(() => {
        phoneDemoFlow2Ref.current.classList.add('phoneDemoFlowPhoneAnimation1');
      }, 200);
    } else if (flowState == 2) {
      phoneDemoFlow2Ref.current.classList.add('phoneDemoFlowPhoneAnimation2');
      setTimeout(() => {
        phoneDemoFlow3Ref.current.classList.add('phoneDemoFlowPhoneAnimation1');
      }, 200);
    }
  }, [flowState]);

  useEffect(() => {

    if (resetDemo == true) {
    
      setFlowState(0);
      phoneDemoFlow1Ref.current.classList.remove('phoneDemoFlowPhoneAnimation2');
      phoneDemoFlow2Ref.current.classList.remove('phoneDemoFlowPhoneAnimation1');
      phoneDemoFlow2Ref.current.classList.remove('phoneDemoFlowPhoneAnimation2');
      phoneDemoFlow3Ref.current.classList.remove('phoneDemoFlowPhoneAnimation1');
    }
  
  }, [resetDemo]); 
  
  

  return (
  <div className='phoneDemoFlowContainer'>
    <div className='phoneDemoFlowBackButton' onClick={() => {setResetDemo(true)}}>
      <p className='phoneDemoFlowBackButtonText'>{"<"} Back</p>
    </div>
    <div className='phoneDemoFlowPhoneContainer'>

      <div className='phoneDemoFlowPhone phoneDemoFlowPhoneAnimation1' ref={phoneDemoFlow1Ref}>
        <img src={image1} alt="Nofine App Demo" className='phoneDemoFlowPhoneImage'/>
        <div className='phoneDemoFlowPhoneNextButton' onClick={() => {setFlowState(1)}}>Next</div>
      </div>

      <div className='phoneDemoFlowPhone' ref={phoneDemoFlow2Ref}>
        <img src={image2} alt="Nofine App Demo" className='phoneDemoFlowPhoneImage'/> 
        <div className='phoneDemoFlowPhoneNextButton' onClick={() => {setFlowState(2)}}>Next</div> 
      </div>

      <div className='phoneDemoFlowPhone' ref={phoneDemoFlow3Ref}>
        <img src={image3} alt="Nofine App Demo" className='phoneDemoFlowPhoneImage'/>
        <div className='phoneDemoFlowPhoneNextButton' onClick={() => {setResetDemo(true)}}>Restart</div>
      </div>

    </div>
  </div>
  );
}

export default PhoneDemoFlow;
