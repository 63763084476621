import './Navbar.css';
import HamburgerButton from '../../components/hamburgerButton/HamburgerButton'
import React from "react";
import {
  Link,
} from "react-router-dom";



const Navbar = ({textColor, backgroundBlur, navHeight, backgroundColor}) => {
  var backgroundColorShade = "white"
  var hamburgerButtonColor = "white"

  if (backgroundColor == "dark") {
    backgroundColorShade = "#252525"
    textColor = "white"
    hamburgerButtonColor = "black"
  }

  return (
    <nav className='nav-container' style={{backgroundColor:backgroundColorShade}}>
          <div className='nav-column'>
              <Link to='/' className='home' style={{color:textColor}}>nofine</Link>
          </div>
          <div className='nav-column navbarLinks'>
            <Link to='/demo'className='link' style={{color:textColor}}>demo</Link>
            <Link to='/about'className='link' style={{color:textColor}}>about</Link>
            <Link to="/contact" className='link' style={{color:textColor}}>contact</Link>
          </div>
          <HamburgerButton className='navbarHamburgerMenue' buttonColor={hamburgerButtonColor}/>
    </nav>
  );
}

export default Navbar;