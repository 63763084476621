import './ContactUsSection.css'
import workTogether from '../../assets/workTogether.png'
import HeaderTextButton from '../headerTextButton/HeaderTextButton'
import React, { useEffect } from 'react';

function ContactUsSection() {
    var demoHeader = "Let us work together"
    var demoText = "Do you think we can benefit each other? Please reach out, we love to chat"
    var demoButtonText = "Get in touch"

    useEffect(() => {
        const textElement = document.querySelector('.ContactUsSectionText');
        const imageElement = document.querySelector('.ContactUsSectionImageContainer');

        if (textElement && imageElement) {
            setTimeout(() => {
                textElement.classList.add('slideInFromLeft');
                imageElement.classList.add('slideInFromRight');
            }
            , 300);
        }
    }, []);

    return (
        <div className="ContactUsSectionBody">
            <div className="ContactUsSectionContainer">
                <div className="ContactUsSectionText">
                    <HeaderTextButton header={demoHeader} text={demoText} buttonText={demoButtonText} buttonLink={"/contact"} width={"80%"} />
                </div>
                <div className="ContactUsSectionImageContainer">
                <img src={workTogether} alt="Working together" className='ContactUsSectionImage'/>
                </div>
            </div>
        </div>
    )
}



export default ContactUsSection