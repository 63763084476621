import './ContactFoundersSection.css'
import linkedinConnect from '../../assets/linkedinConnect.png'
import tikTok from '../../assets/tikTok.png'
import instagramConnect from '../../assets/instagramConnect.png'

function ContactFoundersSection() {

    return (
        <div className="ContactFoundersSectionBody">
            <div className="ContactFoundersSectionContainer">
                <div className="ContactFoundersSectionFounderContainer">
                    <div className="ContactFoundersSectionFounder">
                        <h1>Zakarias Hedenfalk</h1>
                        <h2>Co-Founder</h2>
                        <p>zakarias@nofine.se</p>
                        <p>+46 73 544 34 00</p>
                    </div>
                    <div className="ContactFoundersSectionFounder">
                        <h1>Maximillian Claesson</h1>
                        <h2>Co-Founder</h2>
                        <p>max@nofine.se</p>
                        <p>+46 70 561 46 68</p>
                    </div>
                </div>
                <div className="ContactFoundersSectionConnect">
                    <div className="ContactFoundersSectionHeader">
                        <h1>Connect with us</h1>
                    </div>
                        <div className="ContactFoundersSectionSocialMedia">
                            <div className="ContactFoundersSectionSocialMediaIconContainer">
                                <img src={linkedinConnect} alt="Linkedin" onClick={() => window.open("https://www.linkedin.com/company/86428395", "_blank")} className='ContactFoundersSectionSocialMediaIcon'/>
                            </div>
                            <div className="ContactFoundersSectionSocialMediaIconContainer">
                                <img src={tikTok} alt="Linkedin" onClick={() => window.open("https://www.tiktok.com/@nofine.se", "_blank")} className='ContactFoundersSectionSocialMediaIcon'/>
                            </div>
                            <div className="ContactFoundersSectionSocialMediaIconContainer">
                                <img src={instagramConnect} alt="Instagram" onClick={() => window.open("https://www.instagram.com/nofinesv/", "_blank")} className='ContactFoundersSectionSocialMediaIcon'/>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}



export default ContactFoundersSection