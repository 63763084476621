import './Contact.css'
import BlackLine from '../../components/blackline/BlackLine'
import Navbar from '../../components/navbar/Navbar'
import {Helmet} from "react-helmet";

function Contact() {
    var root = document.getElementById("root")
    root.scrollTo(0, 0);
    return (

        <div className='contactContainer'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact</title>
                <link rel="canonical" href="http://nofine.se/contact" />
            </Helmet>
            <Navbar textColor={"black"} backgroundBlur={""}/>
            <p className='contactTitle'>Let’s get in touch</p>
            <BlackLine lineHeight={'10vh'} />

        <form action="https://api.web3forms.com/submit" method='POST'>

            <input type="hidden" name="access_key" value="6e13a00e-2706-41df-9d2f-16a19499f457" />
            <input type="hidden" name="apikey" value="6e13a00e-2706-41df-9d2f-16a19499f457" /> 

            <div className='formInformationInput'>
                <label for="Name">
                    <input name="Name" type="text" id="Name" placeholder="Name" required/>
                    <span className='formSpan'>Name *</span>
                </label>
                <label for="Email">
                    <input name="Email" type="email" id="Email" placeholder="Email" required/>
                    <span className='formSpan'>Email *</span>
                </label>
                <label for="Phone">
                    <input name="Phone" type="number" id="Phone" placeholder="Phone" required/>
                    <span className='formSpan'>Phone *</span>
                </label>
                <label for="Company">
                    <input name="Company" type="text" id="Company" placeholder="Company"/>
                    <span className='formSpan'>Company</span>
                </label>
            </div>
            <label for="Message" className='Message'>
                <input name="Message" type="text" id="Message" placeholder="Message" required/>
                <span className='formSpan'>Message *</span>
            </label>
            <input type="hidden" name="redirect" value="https://www.nofine.se/contact" />
            <button type="submit" className='button'>Send message</button>
        </form>
        </div>
    )
}

export default Contact
