import './DemoOptionCards.css';
import Navbar from '../navbar/Navbar'
import {Helmet} from "react-helmet";
import { useState, useEffect, useRef } from 'react';


function DemoOptionCards({demoOptionText1, demoOptionText2, demoOptionText3, backgroundImages, setClickState, resetDemo, image1, image2, image3}) {
  var root = document.getElementById("root")
  root.scrollTo(0, 0);

  var [containerIsHovered, setContainerIsHovered] = useState(false);
  var [hoveredOption, setHoveredOption] = useState(false);
  var [lastVisitedOption, setLastVisitedOption] = useState(false);
  var [mobileCarouselState, setMobileCarouselState] = useState(2);
  var [mobileCarouselScrollDirection, setMobileCarouselScrollDirection] = useState(null);


  const demoOptionTextSliderContainerRef = useRef(null);

  const demoOptionMobileArrowsContainerRef = useRef(null);

  const optionTextSliderRef = useRef(null);
  const option1TextRef = useRef(null);
  const option2TextRef = useRef(null);
  const option3TextRef = useRef(null);

  const demoContainerRef = useRef(null);

  const demoOption1Ref = useRef(null);
  const demoOption2Ref = useRef(null);
  const demoOption3Ref = useRef(null);


  useEffect(() => {
    console.log(`containerIsHovered: ${containerIsHovered}`);
    
  }, [containerIsHovered]);
  
  useEffect(() => {

    if (hoveredOption == 1) {
      optionTextSliderRef.current.classList.remove('optionAnimationRight');
      optionTextSliderRef.current.classList.add('optionAnimationLeft');
      option1TextRef.current.classList.add('showOptionText');
      option2TextRef.current.classList.remove('showOptionText');
      option3TextRef.current.classList.remove('showOptionText');

      demoOption1Ref.current.classList.add('demoOptionSelected');
      demoOption2Ref.current.classList.remove('demoOptionSelected');
      demoOption3Ref.current.classList.remove('demoOptionSelected');
    }  else if (hoveredOption == 2) {
      optionTextSliderRef.current.classList.remove('optionAnimationRight');
      optionTextSliderRef.current.classList.remove('optionAnimationLeft');
      option2TextRef.current.classList.add('showOptionText');
      option1TextRef.current.classList.remove('showOptionText');
      option3TextRef.current.classList.remove('showOptionText');

      demoOption2Ref.current.classList.add('demoOptionSelected');
      demoOption1Ref.current.classList.remove('demoOptionSelected');
      demoOption3Ref.current.classList.remove('demoOptionSelected');
    } else if (hoveredOption == 3) {
      optionTextSliderRef.current.classList.remove('optionAnimationLeft');
      optionTextSliderRef.current.classList.add('optionAnimationRight');
      option3TextRef.current.classList.add('showOptionText');
      option1TextRef.current.classList.remove('showOptionText');
      option2TextRef.current.classList.remove('showOptionText');

      demoOption3Ref.current.classList.add('demoOptionSelected');
      demoOption1Ref.current.classList.remove('demoOptionSelected');
      demoOption2Ref.current.classList.remove('demoOptionSelected');
    } else {
      optionTextSliderRef.current.classList.remove('optionAnimationSliding');
      option1TextRef.current.classList.remove('showOptionText');
      option2TextRef.current.classList.remove('showOptionText');
      option3TextRef.current.classList.remove('showOptionText');

      demoOption1Ref.current.classList.remove('demoOptionSelected');
      demoOption2Ref.current.classList.remove('demoOptionSelected');
      demoOption3Ref.current.classList.remove('demoOptionSelected');
    }

    if (!lastVisitedOption && hoveredOption != false) {
      setTimeout(() => {
        optionTextSliderRef.current.classList.add('optionAnimationSliding');
      }, 50);

      }

    setLastVisitedOption(hoveredOption);
  }, [hoveredOption]);




  function selectDemoOption1() {
    demoOption1Ref.current.classList.add('demoSlideOut');
    demoOptionTextSliderContainerRef.current.classList.add('demoSlideOut');
    demoOptionMobileArrowsContainerRef.current.classList.add('demoSlideOut');


    setTimeout(() => {
      demoOption2Ref.current.classList.add('demoSlideOut');
      demoOption3Ref.current.classList.add('demoSlideOut');
      setClickState(1)
    }, 100);
  }

  function selectDemoOption2() {
    demoOption2Ref.current.classList.add('demoSlideOut');
    demoOptionTextSliderContainerRef.current.classList.add('demoSlideOut');
    demoOptionMobileArrowsContainerRef.current.classList.add('demoSlideOut');

    setTimeout(() => {
      demoOption1Ref.current.classList.add('demoSlideOut');
      demoOption3Ref.current.classList.add('demoSlideOut');
      setClickState(2)
    }, 100);
  }

  function selectDemoOption3() {
    demoOption3Ref.current.classList.add('demoSlideOut');
    demoOptionTextSliderContainerRef.current.classList.add('demoSlideOut');
    demoOptionMobileArrowsContainerRef.current.classList.add('demoSlideOut');

    setTimeout(() => {
      demoOption1Ref.current.classList.add('demoSlideOut');
      demoOption2Ref.current.classList.add('demoSlideOut');
      setClickState(3)
    }, 100);
  }

  useEffect(() => {
    
    if (resetDemo == true) {

      demoOption1Ref.current.classList.add('demoHide');
      demoOption2Ref.current.classList.add('demoHide');
      demoOption3Ref.current.classList.add('demoHide');
      demoOptionTextSliderContainerRef.current.classList.add('demoHide');
      demoOptionMobileArrowsContainerRef.current.classList.add('demoHide');

      demoOption1Ref.current.classList.remove('demoSlideOut');
      demoOption2Ref.current.classList.remove('demoSlideOut');
      demoOption3Ref.current.classList.remove('demoSlideOut');
      demoOptionTextSliderContainerRef.current.classList.remove('demoSlideOut');
      demoOptionMobileArrowsContainerRef.current.classList.remove('demoSlideOut');

      setTimeout(() => {
        demoOption1Ref.current.classList.remove('demoHide');
        demoOption2Ref.current.classList.remove('demoHide');
        demoOption3Ref.current.classList.remove('demoHide');
        demoOptionTextSliderContainerRef.current.classList.remove('demoHide');
        demoOptionMobileArrowsContainerRef.current.classList.remove('demoHide');
      }, 500);
    }
  
  }, [resetDemo]);


  useEffect(() => {
    

    if (mobileCarouselState == 1) {
      console.log('1')

      if (mobileCarouselScrollDirection == "left") {
        demoOption2Ref.current.classList.add('optionAnimationMobileRight');

        demoOption3Ref.current.classList.remove('optionAnimationMobileRight');
        demoOption3Ref.current.classList.add('optionAnimationMobileLeft');
      } else if (mobileCarouselScrollDirection == "right") {
        demoOption3Ref.current.classList.add('optionAnimationMobileLeft');

        demoOption2Ref.current.classList.remove('optionAnimationMobileLeft');
        demoOption2Ref.current.classList.add('optionAnimationMobileRight');
      }

      demoOption1Ref.current.classList.remove('optionAnimationMobileLeft');
      demoOption1Ref.current.classList.remove('optionAnimationMobileRight');

      demoOption1Ref.current.classList.add('demoOptionSelectedMobile');
      demoOption2Ref.current.classList.remove('demoOptionSelectedMobile');
      demoOption3Ref.current.classList.remove('demoOptionSelectedMobile');

      option1TextRef.current.classList.add('demoOptionSelectedMobile');
      option2TextRef.current.classList.remove('demoOptionSelectedMobile');
      option3TextRef.current.classList.remove('demoOptionSelectedMobile');

    } else if (mobileCarouselState == 2) {
      console.log('2')

      if (mobileCarouselScrollDirection == "left") {
        demoOption3Ref.current.classList.add('optionAnimationMobileRight');

        demoOption1Ref.current.classList.remove('optionAnimationMobileRight');
        demoOption1Ref.current.classList.add('optionAnimationMobileLeft');
      } else if (mobileCarouselScrollDirection == "right") {
        demoOption1Ref.current.classList.add('optionAnimationMobileLeft');

        demoOption3Ref.current.classList.remove('optionAnimationMobileLeft');
        demoOption3Ref.current.classList.add('optionAnimationMobileRight');
      }

      demoOption2Ref.current.classList.remove('optionAnimationMobileLeft');
      demoOption2Ref.current.classList.remove('optionAnimationMobileRight');

      demoOption2Ref.current.classList.add('demoOptionSelectedMobile');
      demoOption1Ref.current.classList.remove('demoOptionSelectedMobile');
      demoOption3Ref.current.classList.remove('demoOptionSelectedMobile');

      option2TextRef.current.classList.add('demoOptionSelectedMobile');
      option1TextRef.current.classList.remove('demoOptionSelectedMobile');
      option3TextRef.current.classList.remove('demoOptionSelectedMobile');
      
    } else if (mobileCarouselState == 3) {
      console.log('3')

      if (mobileCarouselScrollDirection == "left") {
        demoOption1Ref.current.classList.add('optionAnimationMobileRight');

        demoOption2Ref.current.classList.remove('optionAnimationMobileRight');
        demoOption2Ref.current.classList.add('optionAnimationMobileLeft');
      } else if (mobileCarouselScrollDirection == "right") {
        demoOption2Ref.current.classList.add('optionAnimationMobileLeft');

        demoOption1Ref.current.classList.remove('optionAnimationMobileLeft');
        demoOption1Ref.current.classList.add('optionAnimationMobileRight');
      }

      demoOption3Ref.current.classList.remove('optionAnimationMobileLeft');
      demoOption3Ref.current.classList.remove('optionAnimationMobileRight');

      demoOption3Ref.current.classList.add('demoOptionSelectedMobile');
      demoOption1Ref.current.classList.remove('demoOptionSelectedMobile');
      demoOption2Ref.current.classList.remove('demoOptionSelectedMobile');

      option3TextRef.current.classList.add('demoOptionSelectedMobile');
      option1TextRef.current.classList.remove('demoOptionSelectedMobile');
      option2TextRef.current.classList.remove('demoOptionSelectedMobile');
      
    } else if (mobileCarouselState == 4) {
      setMobileCarouselState(1)
    } else if (mobileCarouselState == 0) {
      setMobileCarouselState(3)
    }
  }, [mobileCarouselState]);


  useEffect(() => {
    if (mobileCarouselScrollDirection == null) {
      demoOption1Ref.current.classList.add('optionAnimationMobileLeft');
      demoOption3Ref.current.classList.add('optionAnimationMobileRight');
    }

  }, [mobileCarouselScrollDirection]);
  

  return (
    <div className='demoOptionCardsBody'>

      <div className='demoOptionCardsArrowsContainer' ref={demoOptionMobileArrowsContainerRef}>
          <div className='demoOptionCardsArrowLeft' onClick={() => {setMobileCarouselState(mobileCarouselState-1); setMobileCarouselScrollDirection("left")}}>{"<"}</div>
          <div className='demoOptionCardsArrowSpacer'></div>
          <div className='demoOptionCardsArrowRight' onClick={() => {setMobileCarouselState(mobileCarouselState+1); setMobileCarouselScrollDirection("right")}}>{">"}</div>
      </div>

      <div className='demoOptionCardsContainer' ref={demoContainerRef} onMouseEnter={() => setContainerIsHovered(true)} onMouseLeave={() => {setContainerIsHovered(false); setHoveredOption(false)}}>
              
        <div className='demoOptionTextSliderContainer' ref={demoOptionTextSliderContainerRef}>
          <div className='demoOptionTextSlider' ref={optionTextSliderRef}>
            <div className='demoOptionText' ref={option1TextRef}>
              <p>{demoOptionText1}</p>
            </div>
            <div className='demoOptionText' ref={option2TextRef}>
              <p>{demoOptionText2}</p>
            </div>
            <div className='demoOptionText' ref={option3TextRef}>
              <p>{demoOptionText3}</p>
            </div>
          </div>
        </div>

        <div className='demoOption' onMouseEnter={() => setHoveredOption(1)} ref={demoOption1Ref} onClick={() => selectDemoOption1()}>
          <img src={image1} alt="Nofine App Demo" className='demoOptionImage'/>
          <div className='demoOptionOpacity'></div>
        </div>
        <div className='demoOption' onMouseEnter={() => setHoveredOption(2)} ref={demoOption2Ref} onClick={() => selectDemoOption2()}>
          <img src={image2} alt="Nofine App Demo" className='demoOptionImage'/>
          <div className='demoOptionOpacity'></div>
        </div>
        <div className='demoOption' onMouseEnter={() => setHoveredOption(3)} ref={demoOption3Ref} onClick={() => selectDemoOption3()}>
          <img src={image3} alt="Nofine App Demo" className='demoOptionImage'/>
          <div className='demoOptionOpacity'></div>
        </div>
      </div>
    </div>
  );
}

export default DemoOptionCards;
