import './HeaderTextButton.css'

function HeaderTextButton({header, text, buttonText, buttonLink, width}) {
    return (
        <div className="HeaderTextButtonBody" style={{width: width}}>
            <h1>{header}</h1>
            <p className='HeaderTextButtonText'>{text}</p>
            <button className="HeaderTextButtonButton" onClick={() => window.open(buttonLink, "_self")}>{buttonText}</button>
        </div>
    )
}

export default HeaderTextButton