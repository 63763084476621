import React from 'react'
import './Privacy.css'
import {Helmet} from "react-helmet";
import Navbar from '../../components/navbar/Navbar';

export default function Privacy() {
  return (
    <div className='privacyContainer'>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Privacy policy</title>
            <link rel="canonical" href="http://nofine.se/privacy" />
        </Helmet>
        <Navbar textColor={"black"} backgroundBlur={true}/>
        <div className='privacyTextContainer'>
            <h1 className='privacyTitle'>Privacy policy</h1>
            <p className='privacyText'>
                
            <strong>Last updated:</strong> December 29, 2022
            <br/>
            This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
            We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the Free Privacy Policy Generator.
            <br/>
            <br/>
            <strong>Types of data collected:</strong>
            <br/>
            - Email Addresses
            <br></br>
            - Name
            <br></br>
            - Usage data

            Usage data is automatically collected when using our service. Usage data may contain information regarding registration dates and purchase history.
            <br/>
            <br/>
            <strong>Use of personal Data</strong>
            <br/>
            The Company may use Personal Data for the following purposes:
            To provide and maintain our Service, including to monitor the usage of our Service.
            To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
            For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
            To manage Your requests: To attend and manage Your requests to Us.
            <br/>
            <br/>
            <strong>Retention of data</strong>
            <br/>
            The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our privacy obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our privacy agreements and policies.
            <br/>
            <br/>
            <strong>Delete your personal data</strong>
            <br/>
            Please contact us at info@nofine.se if you want your data deleted or updated.
            <br/>
            <br/>
            <strong>Children's Privacy</strong>
            <br/>
            Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
            If We need to rely on consent as a privacy basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
            <br/>
            <br/>
            <strong>Changes to this Privacy Policy</strong>
            <br/>
            We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
            We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
            <br/>
            <br/>
            <strong>Contact Us</strong>
            <br/>
            If you have any questions about this Privacy Policy, You can contact us:
            By email: info@nofine.se

            </p>
        </div>
    </div>
  )
}
