import './StartPage.css';
import Hero from '../../components/hero/Hero'
import DemoPage from '../../components/demopage/DemoPage'
import Navbar from '../../components/navbar/Navbar'
import StartPageContact from '../../components/startpagecontact/StartPageContact'
import ScrollPositionIndicator from '../../components/scrollpositionindicator/ScrollPositionIndicator'
import React, { useState, useEffect } from 'react'
import {Helmet} from "react-helmet";

function StartPage() {
  //document.body.style.overflow = "hidden";
  //window.scrollTo(0, 0);


const [position, setPosition] = useState(0)
const [navbarColor, setNavbarColor] = useState("white")


const checkPagePosition = async (event) => {
  var root = document.getElementById("root")
  let height = window.innerHeight;
  var n = root.scrollTop/height
  n = Math.round(n)
  if (n != position || n == 0){
    if (n == 1){
      return setPosition(n), setNavbarColor("black")
    }
    return setPosition(n), setNavbarColor("white")
  }
}


useEffect(() => {
  document.getElementById("root").addEventListener('scroll', checkPagePosition);

  return () =>
  document.getElementById("root").removeEventListener('scroll', checkPagePosition);
}, []); 




/* useEffect(() => {
  window.addEventListener('scroll', console.log("hej"));

  return () =>
    window.removeEventListener('scroll', console.log("hej"));
}, []);  
 */
//document.getElementById("body").addEventListener("touchstart", console.log("hej"));



//window.addEventListener('Touch', console.log("hej"));


/* $('body').bind("mousewheel", function() {
  return false;
}); 
*/

document.addEventListener('wheel', function(e) {
  //console.log(e.wheelDelta, "sdfsd");
});

document.addEventListener('touchstart', function(e) {
  //console.log(e, "sqwwqdfsdf");
});




/* function disable(){
  document.querySelector('body').addEventListener('wheel', preventScroll, {passive: false});
}

function enable(){
    document.querySelector('body').removeEventListener('wheel', preventScroll, {passive: false});
}

function preventScroll(e){
    e.preventDefault();
    e.stopPropagation();

    return false;
} */



/* useEffect(() => {
  window.addEventListener('wheel', listenScrollEvent);

  return () =>
    window.removeEventListener('wheel', listenScrollEvent);
}, []);  */




  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>nofine</title>
        <link rel="canonical" href="http://nofine.se/" />
    </Helmet>
    <ScrollPositionIndicator position={position}/>
    <Navbar textColor={navbarColor} navHeight={'0px'}/>
    <Hero />
    <DemoPage />
    <StartPageContact/>
    </>
  );
}

export default StartPage;
