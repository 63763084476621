import './AppShowcaseSection.css'
import phonesOverlayed from '../../assets/phonesOverlayed.png'
import AppShowcaseText from '../appShowcaseText/AppShowcaseText'
import React, { useEffect } from 'react';

function AppShowcaseSection() {

    useEffect(() => {
        const imageElement = document.querySelector('.AppShowcaseSectionImageContainer');
        const textElement = document.querySelector('.AppShowcaseSectionText');

        if (textElement && imageElement) {
            setTimeout(() => {
                imageElement.classList.add('slideInFromLeft');
                textElement.classList.add('slideInFromRight');
            }
            , 300);
        }
    }, []);

    return (
        <div className="AppShowcaseSectionBody">
            <div className="AppShowcaseSectionContainer">
                <div className="AppShowcaseSectionImageContainer">
                    <img src={phonesOverlayed} alt="Cars parked" className='AppShowcaseSectionImage'/>
                </div>

                <div className="AppShowcaseSectionText">
                    <div style={{width:"75%"}}>
                        <AppShowcaseText/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppShowcaseSection