import './index.css';
import ReactDOM from 'react-dom/client';
import StartPage from '../src/pages/startpage/StartPage';
import AboutUs from '../src/pages/aboutus/AboutUs'
import Contact from './pages/contact/Contact';
import Redirect from './pages/contact/Redirect';
import Footer from './components/footer/Footer';
import Privacy from './pages/privacy/Privacy';
import Terms from './pages/terms/Terms';
import NewStartPage from './pages/newStartpage/NewStartPage';
import NewAboutUs from './pages/newAboutUs/NewAboutUs';
import MobileAppDemoQr from './components/mobileAppDemoQr/MobileAppDemoQr'
import MobileAppDemoQrPage from './pages/mobileAppDemoQrPage/MobileAppDemoQrPage';
import NewFooter from './components/newFooter/NewFooter';
import Demo from './pages/demo/Demo';

import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
document.title = 'nofine';
root.render(
    <Router>
      <Routes>
          <Route path="/" element={
            <>
            <NewStartPage />
            <NewFooter />
            </>
          } />
          <Route path="/contact" element={
            <>
            <Contact />
            <NewFooter />
            </>
          } />
          <Route path="/demo" element={
            <>
            <Demo />
            <NewFooter />
            </>
          } />
          <Route path="/redirect" element={
            <>
            <Redirect />
            <NewFooter />
            </>
          } />
          <Route path="/about" element={
            <>
            <NewAboutUs />
            <NewFooter />
            </>
          } />
          <Route path="/privacy" element={
            <>
            <Privacy />
            <NewFooter />
            </>
          } />
          <Route path="/termsofuse" element={
            <>
            <Terms />
            <NewFooter />
            </>
          } />
      </Routes>
    </Router>
);