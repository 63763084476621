import './AppShowcaseText.css'
import phonesOverlayed from '../../assets/phonesOverlayed.png'

function AppShowcaseText() {
    return (
        <div className="AppShowcaseTextBody">
            <div className="AppShowcaseTextHeader">
                <h1>We are here to help</h1>
            </div>
            <div className="AppShowcaseTextContainer">
                <p className='AppShowcaseTextText'>All information is adapted to you and the time you take the picture</p>
                <p className='AppShowcaseTextLinks'>Avaliable on iOS and Android</p>
            </div>
            
        </div>
    )
}

export default AppShowcaseText