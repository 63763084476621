import './TryDemoInfoSection.css'
import parkingImage from '../../assets/Parking-pana.png'
import HeaderTextButton from '../headerTextButton/HeaderTextButton'
import React, { useEffect } from 'react';

function TryDemoInfoSection() {

    var demoHeader = "Making parking signs easier to figure out"
    var demoText = "We are here to make the parking experience more enjoyable by providing a state of the art solution to ease parking signs"
    var demoButtonText = "Try it out"

    useEffect(() => {
        const textElement = document.querySelector('.TryDemoInfoSectionText');
        const imageElement = document.querySelector('.TryDemoInfoSectionImageContainer');

        if (textElement && imageElement) {
            setTimeout(() => {
                textElement.classList.add('slideInFromLeft');
                imageElement.classList.add('slideInFromRight');
            }
            , 300);
        }
    }, []);

    return (
        <div className="TryDemoInfoSectionBody">
            <div className="TryDemoInfoSectionContainer">
                <div className="TryDemoInfoSectionText">
                    <HeaderTextButton header={demoHeader} text={demoText} buttonText={demoButtonText} buttonLink={"/demo"} width={"80%"} />
                </div>
                <div className="TryDemoInfoSectionImageContainer">
                <img src={parkingImage} alt="Cars parked" className='TryDemoInfoSectionImage'/>
                </div>
            </div>
        </div>
    )
}

export default TryDemoInfoSection