import React, { useState, useEffect, useRef } from 'react';
import './SeenByBar.css';
import ProgressBar from '../progressBar/ProgressBar';

function SeenByBar() {
    const [progress, setProgress] = useState(0);
    const seenByRef = useRef(null);

    useEffect(() => {
        let intervalId;

        const handleIntersection = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // animate progress
                    let currentProgress = 0;
                    intervalId = setInterval(() => {
                        if (currentProgress < 50) {
                            currentProgress += 1; // increase by 1, adjust as needed
                            setProgress(currentProgress);
                        } else {
                            clearInterval(intervalId);
                        }
                    }, 20); // 20ms per interval for 50 values will take 1 second total

                    observer.unobserve(entry.target);
                }
            });
        };

        const options = {
            threshold: 0.1
        };

        const observer = new IntersectionObserver(handleIntersection, options);
        if (seenByRef.current) {
            observer.observe(seenByRef.current);
        }

        return () => {
            if (seenByRef.current) {
                observer.unobserve(seenByRef.current);
            }
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []);

    return (
        <div className="SeenByBarBody" ref={seenByRef}>
            <div className="SeenByBarContainer">
                <div className="SeenByBarHeader">
                    <h1>Seen by</h1>
                </div>
                <div className="SeenByBarBarContainer">
                    <ProgressBar width={"60%"} progress={`50%`} />
                </div>
                <div className="SeenByBarText">
                    <p>{progress}% of the Swedish population</p>
                </div>
            </div>
        </div>
    )
}

export default SeenByBar;


