import React from 'react'
import './NewFooter.css'

function NewFooter () {
  return (
    <div className='NewFooterBody'>
        <div className='NewFooterContainer'>
            <div className='NewFooterTitle'>
                <p>© nofine 2023. All Rights Reserved.</p>
            </div>
            <div className='NewFooterLinks'>
                <p>Cookie policy</p>
                <p>Privacy policy</p>
                <p>Data protection policy</p>
                <p>Terms of service</p>
            </div>
        </div>
    </div>
  )
}

export default NewFooter
