import React from 'react'
import './Redirect.css'
import {
    useNavigate
  } from "react-router-dom";

function Redirect () {

let navigate = useNavigate();

const routeChange = () =>{ 
    let path = `/contact`; 
    navigate(path);
    }

  return (
    <div className='redirectContainer'>
        <p className='redirectTitle'>Tack för du kontaktade oss!</p>
        <button onClick={routeChange}>Gå tillbaka</button>

    </div>
  )
}

export default Redirect
