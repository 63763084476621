import './NewStartPage.css';
import Navbar from "../../components/navbar/Navbar";
import TryDemoInfoSection from "../../components/tryDemoInfoSection/TryDemoInfoSection";
import AppShowcaseSection from '../../components/appShowcaseSection/AppShowcaseSection';
import SeenByBar from '../../components/seenByBar/SeenByBar';
import MentionedInSection from '../../components/mentionedInSection/MentionedInSection';
import ContactUsSection from '../../components/contactUsSection/ContactUsSection';
import {Helmet} from "react-helmet";

function NewStartPage() {
  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Home</title>
      <link rel="canonical" href="http://nofine.se" />
    </Helmet>
    <Navbar textColor={"black"} navHeight={'0px'}/>
    <div id="navbarSpacer"></div>
    <TryDemoInfoSection/>
    <AppShowcaseSection/>
    <SeenByBar/>
    <MentionedInSection/>
    <ContactUsSection/>
    </>
  );
}

export default NewStartPage;