import './NewAboutUs.css';
import Navbar from "../../components/navbar/Navbar";
import AboutUsSection from "../../components/aboutUsSection/AboutUsSection";
import ContactFoundersSection from '../../components/contactFoundersSection/ContactFoundersSection';
import {Helmet} from "react-helmet";

function NewAboutUs() {
  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>About</title>
      <link rel="canonical" href="http://nofine.se/about" />
    </Helmet>
    <Navbar textColor={"black"} navHeight={'0px'}/>
    <div id="navbarSpacer"></div>
    <AboutUsSection/>
    <ContactFoundersSection/>
    </>
  );
}

export default NewAboutUs;