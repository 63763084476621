import React from 'react'
import './Terms.css'
import {Helmet} from "react-helmet";
import Navbar from '../../components/navbar/Navbar';

export default function Terms() {
  return (
    <div className='termsContainer'>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Terms policy</title>
            <link rel="canonical" href="http://nofine.se/terms" />
        </Helmet>
        <Navbar textColor={"black"} backgroundBlur={true}/>
        <div className='termsTextContainer'>
            <h1 className='termsTitle'>Terms of Use</h1>
            <p className='termsText'>
              
            By using our app, you agree to be bound by these Terms of Service.
            <br/><br/>
General Terms of Service
Between Nofine, organization 559400-1835, (referred to as ”We”, ”Us”, “Our”, “Nofine”) and User of the App/Service (“You” etc)
<br/><br/>
1.	Definition and scope of the Application (“The/Our App”)
Nofine provides a mobile application for scanning parking signs with the intention of making them easier to understand. The mobile app is a monthly subscription-based service which allows the user to scan unlimited amounts of parking signs per month. The application is available through the Apple App Store to download.
<br/><br/>
<br/><br/>

2. Disclaimer of Warranties; Limitation of Liability
<br/><br/>
We do not guarantee, represent or warrant that your use of our App will be uninterrupted, timely, secure or error-free.
<br/><br/>
The information and recommendations provided by Our App are intended for general
guidance only. Our App does not guarantee the accuracy or completeness of the information provided, and we are not responsible for any consequences that may result from Your reliance on this information. You are solely responsible for finding and paying for your own parking and for complying with any local parking laws or regulations.
<br/><br/>
You agree that from time to time we may remove Our App for indefinite periods of time or cancel your access to it at any time, without notice to you.
<br/><br/>
You expressly agree that your use of, or inability to use, our App is at your sole risk. The App and all products and services delivered to you through the App are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement. 
<br/><br/>
<br/><br/>

3. Restrictions
<br/><br/>
 
You are allowed to use the content on our App for personal, non-commercial use only.
<br/><br/>
 
You may not do any of the following, unless applicable laws or regulations prohibit these restrictions or you have our written permission to do so:
<br/><br/>
 
1. upload, display or otherwise provide on or through the App any content that: (i) is libelous, defamatory, abusive, threatening, harassing, hateful, offensive or otherwise violates any law or infringes upon the right of any third party (including copyright, trademark, privacy, publicity or other personal or proprietary rights); or (ii) in Our sole judgment, is objectionable or which restricts or inhibits any other person from using the App or which may expose Us or Our users to any harm or liability of any kind;
<br/><br/>

2. download, modify, copy, distribute, transmit, display, perform, reproduce, duplicate, publish, license, create derivative works from, or offer for sale any information contained on, or obtained from or through, the App;
<br/><br/>
3. access or use the App in any manner that could disable, overburden, damage, disrupt or impair the App or interfere with any other party's access to or use of the App or use any device, software or routine that causes the same (including viruses and other malware);
<br/><br/>

4. attempt to gain unauthorized access to the App , or the computer systems or networks connected to the App ;
<br/><br/>

5. circumvent, remove, alter, deactivate, degrade or thwart any technological measure or content protections of the App;
<br/><br/>
6. use any robot, spider, crawlers or other automatic device, process, software or queries that intercepts, “mines,” scrapes or otherwise accesses the App to monitor, extract, copy or collect information or data from or through the App , or engage in any manual process to do the same;
<br/><br/>
7. use the App for illegal, harassing, unethical, or disruptive purposes;
<br/><br/>
8. violate any applicable law or regulation in connection with your access to or use of the App ; or
<br/><br/>
9. access or use the App in any way not expressly permitted by these terms.[A1] 
<br/><br/>
<br/><br/>


4. Changes in these terms
<br/><br/>
 
We reserve the right to change these terms of use at any time. By continuing to use our App after any changes are made, you agree to be bound by the updated terms of use.
<br/><br/>
 
5. Intellectual property rights
<br/><br/>
 
All of the content on our App, including text, images, and other materials, is protected by our intellectual property rights. You must respect these rights and not use our content in any way that infringes on them.
 
<br/><br/>
<br/><br/>


6. Indemnity
<br/><br/>
 
6.1 You shall indemnify, defend and hold Us harmless from and against any and all claims, losses, expenses, or demands of liability, including but not limited to reasonable attorneys’ fees and costs in connection with any claim arising out of Your use of the App and violation of these terms.
<br/><br/>
 
7. Force Majeure
<br/><br/>
You expressly release Us from responsibility for any delay or failure of performance if and to the extent caused by an event that is not reasonably foreseeable or otherwise caused by or under the control of the party claiming force majeure, such as changes in laws and regulations or in the interpretation thereof, acts of authorities, war, acts of war, labour disputes, blockades, sabotage, terrorism, vandalism, accidents and other like events.
<br/><br/>
8. Miscellaneous
<br/><br/>
8.1 You may not assign or pledge its rights and obligations under these terms to any third party except with the prior written consent of Us. We reserve the right to assign our rights and obligations under these terms without notice to You or consent by You . 
<br/><br/>
8.2 The failure of Us to exercise or enforce any right or provision of these terms shall not constitute a waiver of such right or provision. If any provision of these terms is found to be unenforceable or invalid by a court of competent jurisdiction, that provision shall be limited or eliminated to the minimum extent necessary and the terms shall otherwise remain in full force and effect and remain enforceable between the parties.
<br/><br/>
8.3 The contents of these terms of Use shall supersede all previous written or oral commitments and undertakings. 
<br/><br/>
8.4 These terms of use shall be construed in accordance with and be governed by the laws of Sweden, without regard to its conflict of law provisions.   
<br/><br/>
8.5 Any dispute, controversy or claim arising out of or in connection with these terms of use shall be finally settled by Swedish courts, with Stockholm’s district court (Stockholms tingsrätt) as the court of first instance.
<br/><br/>
<br/><br/>
 
Contact information
<br/><br/>

If you have any questions regarding these terms, or any question, complaint or claim with respect to the Service, please contact us at info@nofine.se.


            </p>
        </div>
    </div>
  )
}
