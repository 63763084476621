import './DemoIntroPage.css'


function DemoIntroPage() {

  return (
    <div className='demoIntroPageContainer'>
        <p className='demoIntroPageTitle'>Skrolla ner för att se vad vår teknik kan göra</p>
    </div>
  );
}

export default DemoIntroPage;
