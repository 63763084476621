import { useState } from 'react'
import './HamburgerButton.css'
import Backdrop from '../../components/backdrop/Backdrop'

const HamburgerButton = (buttonColor) => {
    buttonColor = buttonColor.buttonColor
    const [state, setState] = useState("open")
    var line1Class = "hamburgerButtonStripe1"
    var line2Class = "hamburgerButtonStripe2"
    var line3Class = "hamburgerButtonStripe3"
    var buttonClass = "hamburgerButton"
    var link = "#"
    var line_color = "black"

    if (state == "closed") {
        line1Class = "hamburgerButtonStripe1 hamburgerButtonRotate45Left"
        line2Class = "hamburgerButtonStripe2 hamburgerButton0Opacity"
        line3Class = "hamburgerButtonStripe3 hamburgerButtonRotate45Right"
        buttonClass = "hamburgerButton hamburgerMenuOpen"
        link = "#backdropBlur"

    } 

    console.log(buttonColor)
    if (buttonColor == "black") {
        line_color = "white"
    }
    

  return (
    <>
        <a href={link} className={buttonClass} onClick={() => setState(changeState(state))}>
            <div className={line1Class} style={{width:"26px", backgroundColor:line_color}}></div>
            <div className={line2Class} style={{width:"19px", backgroundColor:line_color}}></div>
            <div className={line3Class} style={{width:"28px", backgroundColor:line_color}}></div>
        </a>
        <Backdrop/>
    </>
  );
}

export default HamburgerButton;


function changeState(state) {
    console.log("")
    if (state == "open"){
        state = "closed"
    } else {
        state = "open"
    }
    return state
}