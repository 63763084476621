import './Background.css';

import React, { useState, useEffect } from 'react'

function Background() {
const [color, setColor] = useState("white")

const listenScrollEvent = (event) => {
  var root = document.getElementById("root")
  var yPosition = root.scrollTop
  let height = window.innerHeight;
  if (yPosition < 1.3*height) {
    return setColor("white")
  } else if (yPosition >= 1.3*height) {
    return setColor("#171717")
  } 
}

useEffect(() => {
  document.getElementById("root").addEventListener('scroll', listenScrollEvent);

  return () =>
  document.getElementById("root").removeEventListener('scroll', listenScrollEvent);
}, []);

  return (
    <div className='gradient' style={{backgroundColor: color}}></div>
  );
}

export default Background;
